<template>
    <el-dialog
    v-model="dialogVisible"
    :title="type?'编辑用户':'添加用户'"
    width="40%">
    <el-form
    ref="ruleFormRef"
    :model="ruleForm"
    :rules="rules"
    label-width="100px"
    size="large">
        <el-form-item label="账号" prop="username">
            <el-input v-model="ruleForm.username" :disabled="ruleForm.id&&ruleForm.id==1" />
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="type == 0">
            <el-input v-model="ruleForm.password" type="password" autocomplete="off" show-password />
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
            <el-input v-model="ruleForm.mobile" />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
            <el-input v-model="ruleForm.email" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
            <el-radio-group v-model="ruleForm.status">
                <el-radio :label="0">禁用</el-radio>
                <el-radio :label="1">启用</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="角色" prop="roleID">
            <el-select style="width:100%" v-model="ruleForm.roleID" placeholder="请选择角色">
                <el-option v-for="(item, index) in rolesOption" :key="index" :label="item.roleName" :value="item.id" />
            </el-select>
        </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button  color="#803a15" dark  @click="formSubmit('ruleFormRef')">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { addUser, editUser } from '@/api/user.js'
import { RolesList } from '@/api/role.js'
import { isvalidPwd, isvalidPhone } from '@/utils/validate.js'
import { encrypt } from '@/utils/rsaEncrypt'
export default {
    emits:['OK'],
    data(){
        const validPwd = (rule, value, callback) => {
            if (value) {
                if(isvalidPwd(value)){
                    callback()
                }else{
                    callback(new Error('密码包含字母、数字、特殊字符且长度至少八位'))
                }
            } else {
                callback()
            }
        }
        const validPhone = (rule, value, callback) => {
            if (value) {
                if(isvalidPhone(value)){
                    callback()
                }else{
                    callback(new Error('请输入正确的手机号'))
                }
            } else {
                callback()
            }
        }
        return{
            dialogVisible:false,
            type:0,
            rolesOption:[],
            ruleForm:{
                username:'',
                password:'',
                mobile:'',
                email:'',
                status:1,
                roleID:'',
            },
            rules:{
                username:{ required: true, message: '账户名不能为空', trigger: 'blur' },
                password: [
                    { required: true, message: '密码不能为空', trigger: 'blur'},
                    { validator: validPwd, trigger: 'blur' }
                ],
                mobile:[
                    { required: true, message: '手机号不能为空', trigger: 'blur'},
                    { validator: validPhone, trigger: 'blur' }
                ],
                email:[
                    { required: true, message: '邮箱不能为空', trigger: 'blur',},
                    { type: 'email', message: '请输入正确的邮箱', trigger: 'blur'},
                ],
                roleID:{ required: true, message: '请选择邮箱', trigger: 'blur'},
            }
        }
    },
    mounted(){
        this.GetRoles()
    },
    methods:{
        GetRoles(){
            RolesList().then(r=>{
                this.rolesOption = r.data
            })
        },
        open(type,data){
            this.type = type
            this.dialogVisible = true
            if(type == 1){
                this.ruleForm = {
                    id:data.id,
                    username:data.username,
                    mobile:data.mobile,
                    email:data.email,
                    status:data.status,
                    roleID:data.roleID
                }
            }else{
                this.$nextTick(()=>{
                    this.$refs.ruleFormRef.resetFields() 
                })
            }
        },
        formSubmit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true
                    if(this.type == 0){
                        this.AddData(this.ruleForm)
                    }else{
                        this.EditData(this.ruleForm)
                    }
                } else {
                    return false;
                }
            });
        },
        AddData(datas){
            let data = JSON.parse(JSON.stringify(datas))
            data.password = encrypt(data.password)
            addUser(data).then(r=>{
                this.loading = false
                if(r.code==200){
                    this.$message({
                        type: 'success',
                        message: '新增成功!'
                    });
                    this.$emit('OK',0)
                    this.dialogVisible = false
                }else{
                    this.$message({
                        type: 'warning',
                        message: '新增失败!'
                    });
                }
            }).catch(()=>{
                this.loading = false
            })
        },
        EditData(data){
            editUser(data).then(r=>{
                this.loading = false
                if(r.code==200){
                    this.$message({
                        type: 'success',
                        message: '编辑成功!'
                    });
                    this.$emit('OK',1)
                    this.dialogVisible = false
                }else{
                    this.$message({
                        type: 'warning',
                        message: '编辑失败!'
                    });
                }
            }).catch(()=>{
                this.loading = false
            })
        },
    }
}
</script>